import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="footer"
      style={{ backgroundColor: "rgb(255 255 255)" }}
    >
      <Container>
        <div className="footer-top">
          <Row>
            <Col lg={3} md={6} className="footer-info">
              <Link to="/" className="logo d-flex align-items-center">
                <img
                  src="assets/img/eglipsesolution logo.png"
                  alt="logo"
                  style={{ maxHeight: "100px", maxWidth: "150px" }}
                />
              </Link>
              <p style={{ textAlign: "justify" }}>
                Eglipese solution has a team of experts with 15 years of
                typesetting, composition, editorial, publishing expertise who
                provide technological solutions to all technical issues.
              </p>
              <div className="social-links mt-3">
                <Link
                  to="https://wa.me/+9500116829"
                  className="whatsapp"
                  target="_blank"
                >
                  <i className="bi bi-whatsapp"></i>
                </Link>
                <Link
                  to="https://www.facebook.com/"
                  className="facebook"
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/"
                  className="instagram"
                  target="_blank"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
              </div>
            </Col>
            <Col lg={3} md={6} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/About">About</Link>
                </li>
                <li>
                  <Link to="/Service">Service</Link>
                </li>
                <li>
                  <Link to="/Sample">Sample</Link>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} className="footer-contact">
              <h4>Address</h4>
              <p>
                BrandingWorld
                <br />
                Rathinam Complex,
                <br />
                2/12, Avenue Rd, Ponnangipuram,
                <br />
                Nungambakkam,
                <br />
                Chennai, Tamil Nadu 600034.
              </p>
            </Col>
            <Col lg={3} md={6} className="footer-contact">
              <h4>Contact Us</h4>
              <p>
                <strong>Phone:</strong>{" "}
                <Link to="tel: +9500116829"> +9500116829</Link>
                <br />
                <strong>Email:</strong>{" "}
                <Link to="mailto:mani.eglipese@gmail.comt">
                  mani.eglipese@gmail.com{" "}
                </Link>
                <br />
                <strong>Web:</strong>{" "}
                <Link to="https://Eglipesesolution.in/" target="blank">
                  Eglipesesolution.in
                </Link>
                <br />
              </p>
            </Col>
          </Row>
        </div>
        <div className="footer-bottom text-center">
          <p>
            &copy; Copyright <strong>Eglipese Solution</strong>. All Rights
            Reserved
          </p>
          <p className="credits">
            Designed by{" "}
            <Link
              to="https://brandingworld.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brandingworld.net
            </Link>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
