import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";


const About = () => {
  useEffect(() => {
    document.title = "About Us | Eglipese solution";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "A world of talent at your fingertips we are dedicated to providing top-notch It solutions and services to help businesses "
      );
  }, []);
  return (
    <div>
      <Header />
      <section id="about" className="about mt-5">
        <div className="container">
        <header className="section-header">
            <p>About Us</p>
          </header>
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className="content text-justify" style={{backgroundColor:"rgb(224 247 237)"}}>
                <h2>Welcome to Eglipese solution</h2>
                {/* <h1>
                  <b>A world of talent at your fingertips</b>
                </h1> */}
                <p>
                  Eglipese solution has a team of experts with 15 years of
                  typesetting, composition, editorial, publishing expertise who
                  provide technological solutions to all technical issues. We
                  offer comprehensive typesetting, printing, and publishing
                  services. We are specialized in formatting and designing of
                  history and heritage books, journals, catalogues, dictionary,
                  academic, STM, K-12, law, legal, literature, scholarly works
                  and children illustrated books.
                  <br></br>
                  <br></br>
                  We believe in delivering quality content at reasonable prices.
                  We believe in the latest publishing technology to deliver
                  quality products and services for the benefit of our customers
                  and partners.
                  <br></br>
                  <br></br> <b>Our Approach:</b> We believe that every business
                  is unique, and that's why we take a personalized approach to
                  every project we undertake. Our team collaborates closely with
                  you to gain a deep understanding of your objectives,
                  challenges, and aspirations. We then craft customized IT
                  strategies that align with your goals, optimize your
                  operations, and drive growth.Our well-experienced and
                  dedicated staffs can handle Composition (Pagination), Graphics
                  work, Proof reading (character to character) and Editorial
                  work. They have thorough knowledge in STM journals and books
                  workflow in various international publisher like Pearson,
                  McGraw-Hill, McMillan, Norton, Pearson, WSPS, Springer,
                  Blackwell, digital services to Ingram, amazon kindle, D2D,
                  Lulu, etc.
                </p>
               
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <img src="assets/img/" className="img-fluid" alt="Image" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
