import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Sample = () => {
  useEffect(() => {
    document.title = "Sample | Eglipese solution ";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "Welcome to Eglipese solution");
  }, []);

  return (
    <div>
      <Header />
      <div
        id="hero mt-5"
        className="hero d-flex align-items-center"
        style={{ height: "1650px", marginTop: "30px" }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{
                  borderRadius: "20px",
                  width: "500px",
                  height: "350px",
                }}
                className="img-fluid rounded mb-4"
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{
                  borderRadius: "20px",
                  width: "500px",
                  height: "350px",
                }}
                className="img-fluid rounded mb-4"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{
                  borderRadius: "20px",
                  width: "500px",
                  height: "350px",
                }}
                className="img-fluid rounded mb-4"
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{
                  borderRadius: "20px",
                  width: "500px",
                  height: "350px",
                }}
                className="img-fluid rounded mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Sample;
