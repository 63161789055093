import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Testimonials = () => {
  useEffect(() => {
    document.title = "Testimonial | Eglipese solution ";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "Welcome to Eglipese solution");
  }, []);
  return (
    <div>
      <Header />
      <div
        id="hero"
        className="hero mt-5 d-flex align-items-center"
        style={{ height: "600px", marginTop: "30px" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                className="img-fluid rounded mb-4"
                alt="image"
              />
            </div>
            <div className="col-lg-6 col-md-8" data-aos-delay="200">
              <img
                src="assets/img/"
                className="img-fluid rounded mb-4"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Testimonials;
