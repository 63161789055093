import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
import { TiTick } from "react-icons/ti";

const Home = () => {
  useEffect(() => {
    document.title = "Home | Eglipese solution ";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "Eglipese solution");
  }, []);
  const CustomListItem = ({ children }) => (
    <div style={{ display: "flex", alignItems: "center", fontSize: "20px" }}>
      <TiTick style={{ marginRight: "5px" }} />
      <li>{children}</li>
    </div>
  );

  return (
    <div>
      <Header />

      <div
        id="carouselExampleControls"
        className="carousel slide carousel-fade"
        data-bs-interval="3000"
        data-bs-ride="carousel"
        style={{ height: "100vh" }}
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <section
              className="hero d-flex align-items-center"
              style={{ backgroundColor: "#e9c456" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/"
                      alt="image"
                      className="img-fluid"
                      style={{ maxWidth: "90%", height: "auto" }}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3 style={{ color: "#19c770", fontWeight: "800" }}>
                      Great experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Welcome to Eglipese solution
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      Eglipese solution is an independent typesetting company
                      offers{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="carousel-item">
            <section
              className="hero d-flex align-items-center"
              style={{ backgroundColor: "#e9c456" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/"
                      alt="image"
                      className="img-fluid"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3 style={{ color: "#19c770", fontWeight: "800" }}>
                      Great experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Welcome to Eglipese<br></br> solution
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      e-publishing solutions for press and publisher. We can
                      take your specification and format it into compatible to
                      printers and electronic readers{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="carousel-item">
            <section
              className="hero d-flex align-items-center"
              style={{ backgroundColor: "#e9c456" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/"
                      alt="image"
                      className="img-fluid"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3 style={{ color: "#19c770", fontWeight: "800" }}>
                      Great experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Welcome to Eglipese<br></br> solution
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      The company was founded by a group of typesetting
                      professionals who are dedicated to publish the best in
                      various topical areas like science, technology,
                      mathematics, history and medicine.{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="carousel-item">
            <section
              className="hero d-flex align-items-center"
              style={{ backgroundColor: "#e9c456" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/"
                      alt="image"
                      className="img-fluid"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3 style={{ color: "#19c770", fontWeight: "800" }}>
                      Great experience
                    </h3>
                    <h1 style={{ color: "white" }}>
                      Welcome to Eglipese<br></br> solution
                    </h1>
                    <br></br>
                    <h4 data-aos-delay="400" style={{ color: "whitesmoke" }}>
                      Eglipese solution has a team of experts with 15 years of
                      typesetting, composition, Editorial, publishing expertise
                      who provide technological solutions to all technical
                      issues.{" "}
                    </h4>
                    <div data-aos-delay="600">
                      <div className="text-center text-lg-start">
                        <Link
                          to="/ContactUs"
                          className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                        >
                          <span>Let's Talk Now</span>
                          <i className="bi bi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden" style={{ color: "black" }}>
            Previous
          </span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container ">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-10">
              <h3 class="text-success text-center mb-4">
                Welcome to Eglipese solution!
              </h3>
              <p class="text-justify">
                Eglipese solution is an independent typesetting company offering
                e-publishing solutions for presses and publishers. We can take
                your specifications and format them into compatible formats for
                printers and electronic readers. The company was founded by a
                group of typesetting professionals who are dedicated to
                publishing the best in various topical areas like science,
                technology, mathematics, history, and medicine.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style={{ color: "#19c770", fontWeight: "800" }}>
                Who are we
              </h3>
              <h1>
                In a short time span, we've made waves. Success fuels our drive.
              </h1>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Eglipese solution has a team of experts with 15 years of
                typesetting, composition, Editorial, publishing expertise who
                provide technological solutions to all technical issues. We
                offer comprehensive typesetting, printing, and publishing
                services. We are specialized in formatting and designing of
                history and heritage books, academic, STM, K-12, law, legal,
                literature, scholarly works and children illustrated books.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/About"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Explore More
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt="image"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container ">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
              <h3 class="text-success text-center mb-4">Our Mission</h3>
              <p class="text-justify">
                Our mission is to deliver our services with 100% customer
                satisfaction by understanding their requirements and keeps the
                clients happy and to create a world where people work to make a
                life, not just a living. Make people's lives better every day
                naturally.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3 style={{ color: "#19c770x", fontWeight: "800" }}>
                Why Eglipese solution
              </h3>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "left",
                  flexDirection: "column",
                  listStyleType: "none",
                }}
              >
                <CustomListItem>Cost effective</CustomListItem>
                <CustomListItem>24/7 Operations</CustomListItem>
                <CustomListItem>Quick response</CustomListItem>
                <CustomListItem>Fast turnaround time</CustomListItem>
                <CustomListItem>Consistent quality</CustomListItem>
                <CustomListItem>Skilled manpower</CustomListItem>
                <CustomListItem>Well defined process</CustomListItem>
              </ul>

              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <div className="text-center text-lg-start">
                    <Link
                      to="/ContactUs"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Let's Talk Now</span>
                      <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt="image"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container ">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10">
              <h3 class="text-success text-center mb-4">Quality policy</h3>
              <p class="text-justify">
                We follow the industries best practices and procedures and use
                the Quality Management System (QMS) to manage projects
                effectively and efficiently to maintain high quality while
                delivering all our projects to customers within the agreed
                timelines.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="call-to-action"
        class="call-to-action"
        style={{ backgroundColor: "#e9c456" }}
      >
        <div class="container" data-aos="fade-up">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <h3>Dedicated support team</h3>
              <p></p>
              <button
                style={{
                  backgroundColor: "#19c770",
                  color: "white",
                  padding: "5px 20px",
                  borderRadius: "25px",
                  border: "none",
                }}
              >
                <Link
                  class="cta-btn"
                  to="tel:+9500116829"
                  style={{ color: "white" }}
                >
                  Call Now
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
