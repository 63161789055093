import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { TbSquareRoundedCheck } from "react-icons/tb";

const Service = () => {
  useEffect(() => {
    document.title = "Service | Eglipese solution ";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", "Welcome to Eglipese solution");
  }, []);
  return (
    <div>
      <Header />
      <div className="container mt-5">
        <div className="row">
          <h3
            className=" mt-5 row"
            style={{
              padding: "20px 0",
              lineHeight: "1.5",
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "700",
              fontSize: "38px",
              color: "#012970",
              fontFamily: "",
            }}
          >
            Services
          </h3>
          <div className="col-lg-6">
            <img
              src="assets/img/"
              className="img-fluid rounded"
              alt="image"
            />
          </div>
          <div className="col-lg-6">
            <ul className="list-unstyled">
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />
                Editorial services
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />
                Technical Editing
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Reference Editing (APA, Chicago, MLA, Vancouver)
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Copyediting
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Master Copying
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />
                Proofreading
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />
                Typesetting services Books: History and heritage books, Trade,
                STM, Academic, ELT, Law, Legal, Literature, K–12, children
                illustrated books
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />
                Typesetting services Journals: STM Journals, Finance & Economic
                Journals, conference proceedings, Catalogues, Dictionary, Yellow
                pages, etc.
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Graphic Designing work: Artwork, Color correction, Redrawing,
                Relabeling, Cover design, Ad-design, Illustrations, Situational
                art, Maps, and Complicated charts
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Indexing
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Graphic Designing work: Artwork, Color correction, Redrawing,
                Relabeling, Cover design, Ad-design, Illustrations, Situational
                art, Maps, and Complicated charts
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Digital Services: ePub file creation, Re-flowable, Fixed-layout,
                Apple, Kindle, Nook, etc
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                E-Learning: HTML5-based interactive module development, Content
                development, Audio/Video editing etc
              </li>
              <li>
                <TbSquareRoundedCheck
                  style={{
                    marginRight: "5px",
                    color: "#19c770",
                    fontSize: "25px",
                  }}
                />{" "}
                Printing and Packaging services
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Service;
